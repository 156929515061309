import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Input,
  Label,
  Card,
  Row,
  Col,
  FormGroup,
  Spinner,
  Badge,
  Table,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { getAllConfigs } from '../../../actions/promotion/generals';
import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';

import debounce from 'lodash.debounce';
import { DISCOUNT_TYPES } from '../../../constants';
import {
  getCombos,
  createOrUpdateCombo,
} from '../../../actions/promotion/combo';

const Combo = ({ promotionId, effectId }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState('');

  const [combos, setCombos] = useState([]);
  const [csvFile, setCsvFile] = useState(null);

  const [comboName, setComboName] = useState('');
  const [discountType, setDiscountType] = useState(DISCOUNT_TYPES?.[0]?.value);
  const [discountValue, setDiscountValue] = useState('');
  const [maxDiscount, setMaxDiscount] = useState('');
  const [products, setProducts] = useState([]);
  const [sku, setSku] = useState(null);
  const [qty, setQty] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isDownloading, setIsDownloading] = useState(null);

  const toggle = (id) => {
    try {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
      setCollectionId('');
      setComboName(null);
      setCsvFile(null);
      setEditingId(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSku('');
    setQty('');
  }, [products]);

  useEffect(() => {
    getCombosFunc();
  }, []);

  const getCombosFunc = async () => {
    try {
      const inputData = {
        promotionId: promotionId,
        pageOffset: pageOffset,
        pageSize: 10,
      };

      const { data, message, success } = await getCombos(inputData);

      if (success) {
        setCombos(data?.combos);
        setTotalPages(data?.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetToInitialState = () => {
    setComboName('');
    setProducts([]);
    setDiscountType(DISCOUNT_TYPES?.[0]?.value);
    setDiscountValue('');
    setMaxDiscount('');
  };

  const createOrUpdateComboFunc = async () => {
    try {
      setIsLoading(true);
      const inputData = [
        {
          promotionId: promotionId,
          comboId: 'combo-2',
          comboName: comboName,
          version: 0,
          rule: {
            products: products,
            discountType: discountType,
            discountValue: Number(discountValue),
            maxDiscount: Number(maxDiscount),
          },
          effectIds: [effectId],
        },
      ];
      const { success, message } = await createOrUpdateCombo(inputData);
      if (success) {
        alertSuccess(message);
        resetToInitialState();
        getCombosFunc();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } catch (error) {
      console.log(error);
    }
  };

  const selectComboHandler = async (combo, comboIndex) => {
    try {
      setIsLoading(true);
      const updatedCombo = { ...combo };
      const updatedCombos = [...combos];

      const index = combo?.effectIds?.findIndex((item) => item === effectId);

      if (index === -1) {
        updatedCombo.effectIds = [...updatedCombo?.effectIds, effectId];
      } else {
        updatedCombo.effectIds = [
          ...updatedCombo?.effectIds?.filter((item) => item !== effectId),
        ];
      }
      const inputData = [updatedCombo];
      const { success } = await createOrUpdateCombo(inputData);
      updatedCombos[comboIndex] = { ...updatedCombo };
      setCombos([...updatedCombos]);
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex flex-column w-100">
        {combos?.map((combo, index) => {
          return combo?.effectIds?.includes(effectId) ? (
            <div class="container mb-3 row">
              <div class="col-6">
                {combo?.rule?.products?.map((product, index) => (
                  <div
                    className={`row border-bottom border-start p-2 ${
                      index === 0 ? 'border-top' : ''
                    }`}
                  >
                    <div class="col-6 border-end">{product?.sku}</div>
                    <div class="col-6">{product?.qty}</div>
                  </div>
                ))}
              </div>

              <div class="col-4 border">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                  <div className="text-capitalize">
                    {combo?.rule?.discountType}
                  </div>
                  <div>{combo?.rule?.discountValue}</div>
                </div>
              </div>
            </div>
          ) : null;
        })}
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setIsOpenModal(true);
          }}
          className="pt-1 pb-1 w-25"
        >
          Choose combo
        </Button>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Please select the combos you wish to include.
        </ModalHeader>
        <ModalBody className="combos-modal">
          <div className="hide-accordion-arrow">
            <Accordion open={open} toggle={toggle}>
              <AccordionItem className="mb-3">
                <AccordionHeader targetId={`${combos?.length}`}>
                  <i className="bx bx-plus fs-14 pe-2"></i>
                  Create new combo
                </AccordionHeader>
                <AccordionBody accordionId={`${combos?.length}`}>
                  <FormGroup>
                    <Label>Combo Name</Label>
                    <Input
                      type="text"
                      id="field-combo-name"
                      placeholder="Combo Name"
                      onChange={(e) => {
                        setComboName(e?.target?.value);
                      }}
                      value={comboName}
                    />
                  </FormGroup>
                  <div className="d-flex row">
                    <FormGroup className="mb-1 w-50">
                      <Label for="discount-type-select">Discount Type</Label>
                      <Input
                        type="select"
                        id="discount-type-select"
                        onChange={(e) => {
                          setDiscountType(e?.target?.value);
                        }}
                        value={discountType}
                      >
                        {DISCOUNT_TYPES?.map((item, idx) => (
                          <option value={item?.value} key={`dt-${idx}`}>
                            {item?.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup className="w-50">
                      <Label>Discount Value</Label>
                      <Input
                        type="text"
                        id="field-discount-value"
                        placeholder="Discount Value"
                        onChange={(e) => {
                          setDiscountValue(e?.target?.value);
                        }}
                        value={discountValue}
                      />
                    </FormGroup>
                    <FormGroup className="w-50">
                      <Label>Max Discount</Label>
                      <Input
                        type="text"
                        id="field-discount-value"
                        placeholder="Max Discount"
                        onChange={(e) => {
                          setMaxDiscount(e?.target?.value);
                        }}
                        value={maxDiscount}
                      />
                    </FormGroup>
                  </div>

                  <Label>Products</Label>

                  <Table bordered>
                    {products?.length > 0 ? (
                      <thead>
                        <tr>
                          <th>
                            <span>SKU</span>
                          </th>
                          <th>QTY</th>
                        </tr>
                      </thead>
                    ) : null}
                    <tbody>
                      {products?.map((product, index) => (
                        <tr key={index}>
                          <td>{product?.sku}</td>
                          <td>{product?.qty}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="2">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <Input
                                type="text"
                                id="field-sku"
                                placeholder="SKU"
                                value={sku}
                                onChange={(e) => setSku(e?.target?.value)}
                              />
                            </div>
                            <Col
                              xl={3}
                              lg={3}
                              md={12}
                              sm={12}
                              xs={12}
                              className="ps-0 pe-0"
                            >
                              <Input
                                type="number"
                                id="field-qty"
                                placeholder="QTY"
                                onChange={(e) => setQty(e?.target?.value)}
                                value={qty}
                              />
                            </Col>
                            <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => {
                                  setProducts([
                                    ...products,
                                    { sku: sku, qty: Number(qty) },
                                  ]);
                                  setSku(null);
                                  setQty(null);
                                }}
                              >
                                Add
                              </button>
                            </Col>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>

                  <div className="d-flex flex-row  mt-3">
                    <Button
                      color="dark"
                      size="sm"
                      onClick={() => {
                        setOpen('');
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        createOrUpdateComboFunc();
                      }}
                      //   disabled={!comboName || !csvFile}
                      className="ms-2"
                    >
                      Create
                    </Button>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="hide-accordion-arrow accordion-item-border-none">
            <Accordion open={open} toggle={() => {}}>
              {combos?.map((combo, index) => {
                return (
                  <AccordionItem>
                    <AccordionHeader targetId={`${index}`}>
                      <div className="d-flex flex-row justify-content-between align-items-center w-100">
                        <div class="container">
                          <div class="row">
                            <div class="col-auto border p-2 ">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="auth-remember-check"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectComboHandler(combo, index);
                                  }}
                                  checked={combo?.effectIds?.includes(effectId)}
                                />
                              </div>
                            </div>

                            <div class="col-6">
                              {combo?.rule?.products?.map((product, index) => (
                                <div
                                  className={`row border-bottom p-0 ${
                                    index === 0 ? 'border-top' : ''
                                  }`}
                                >
                                  <div class="col-6 border-end">
                                    {product?.sku}
                                  </div>
                                  <div class="col-6">{product?.qty}</div>
                                </div>
                              ))}
                            </div>

                            <div class="col-4 border">
                              <div className="d-flex gap-0 flex-column align-items-center justify-content-center h-100">
                                <div className="text-capitalize">
                                  {combo?.rule?.discountType}
                                </div>
                                <div>{combo?.rule?.discountValue}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <Table bordered>
                          <tbody>
                            <tr key={combo?.rule?.products?.length + 1}>
                              
                              <td>
                                <div className="d-flex flex-row">
                                  <Button
                                    color="info"
                                    size="sm"
                                    className="me-2 pt-1 pb-0 h-25"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // downloadCollectionFunc(combo?._id);
                                    }}
                                  >
                                    {isDownloading === combo?._id ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      <i className="bx bx-download fs-15"></i>
                                    )}
                                  </Button>

                                  <Button
                                    color="info"
                                    size="sm"
                                    className="pt-1 pb-0 h-25"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // setEditingId(combo?._id);
                                      setOpen(`${index}`);
                                      //   setCollectionName(
                                      //     collection?.values?.collectionName
                                      //   );
                                      //   setCollectionId(collection?._id);
                                    }}
                                  >
                                    <i className="bx bxs-edit fs-15"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table> */}
                      </div>
                    </AccordionHeader>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center mt-4">
            <div className="align-items-center justify-content-between row text-center text-sm-start">
              {totalPages > 1 ? (
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                    <li
                      className={`page-item  ${
                        pageOffset < 1 ? 'disabled' : ''
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          setPageOffset(pageOffset - 1);
                        }}
                      >
                        ←
                      </Link>
                    </li>

                    {pages?.map((page, index) => {
                      return (
                        <li
                          className={`page-item ${
                            pageOffset === index ? 'active' : ''
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => setPageOffset(index)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      );
                    })}

                    <li className="page-item">
                      <Link
                        to="#"
                        className={`page-link ${
                          pageOffset + 1 >= totalPages ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          setPageOffset(pageOffset + 1);
                        }}
                      >
                        →
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setIsOpenModal(false);
              }}
              type="button"
            >
              Save
            </Button>
          </div>
          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Combo;
